import React from "react";
// import AnnouncementBar from "./AnnouncementBar/AnnouncementBar";
import HeaderCart from "./HeaderCart/HeaderCart";
import Logo from "./Logo/Logo";
import NavBar from "./Navbar/NavBar";

export default function Header({data}) {
    return (
        <div>
            {/* <AnnouncementBar
                data={data && data}
            /> */}
            <div style={{ paddingTop: data && `${data.spacing}px`, paddingBottom: data && `${data.spacing}px` }}>
                <div className="container">
                    {data && data.logo_alignment === "left" ?
                        <div className="row align-items-center">
                            <div className="col-sm-2">
                                <Logo image={data && data.logo_image} width={data && data.logo_width} />
                            </div>
                            <div className="col-sm-8">
                                <NavBar />
                            </div>
                            <div className="col-sm-2">
                                <div className="row justify-content-end">
                                    <div className="col">
                                        <HeaderCart
                                            data={data && data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="row">
                                <div className="col-sm-4">
                                </div>
                                <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                    <Logo image={data && data.logo_image} width={data && data.logo_width} />
                                </div>
                                <div className="col-sm-4">
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <HeaderCart
                                                data={data && data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavBar />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}