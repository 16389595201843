import React from "react";

export default function HeaderCart({ data }) {
    return (
        <div>
            {data && data.show_search &&
                <button>search</button>
            }
            <button>cart</button>
            <button>account</button>
        </div>
    )
}