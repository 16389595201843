import React, { useState } from "react";

export default function NavBar() {
    const [nav, setNav] = useState([
        { label: "Home", link: "", slug: "home" },
        { label: "Clothing", link: "", slug: "clothing" },
        { label: "Accessories", link: "", slug: "accessories" },
        { label: "Blog", link: "", slug: "blog" },
    ])
    return (
        <div sx={{ width: '100%' }}>
            <div className="row">
                {nav.length > 0 && nav.map((opt, index) => {
                    return (
                        <div className="col" key={index.toString()}>
                            <button>{opt.label}</button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}