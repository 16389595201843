import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from "./components/Header/Header";
import { useEffect, useState } from 'react';


const App = () => {
  const [dataVal, setdataVal] = useState({})
  // const getFetchData = () => {
  //   fetch("http://localhost:3000/db").then(res => res.json()).then(result =>
  //     setdataVal(result)
  //   )
  // }
  // useEffect(() => {
  //   getFetchData()
  // }, [])
  useEffect(()=>{
    setdataVal(document.querySelector("body").dataset.object)
  }, [])
  console.log("dataVal------:", dataVal)
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Header data={dataVal !== {} && dataVal !== undefined && dataVal.components && dataVal.components.header || {}} />} exact />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
